import { Site } from '../models';

export interface InitialStateInterface {
	loggedIn: boolean;
	checkTokenLoading: boolean,
	currentSite: Site,
	refreshSite: boolean,
	user?: {
		id: number,
		lastName: string;
		fistName: string;
		site: Site;
		type: string,
		roles: string[]
	};
}

export const initialState: InitialStateInterface = {
	loggedIn: false,
	user: undefined,
	checkTokenLoading: false,
	currentSite: new Site(),
	refreshSite: false,
};
