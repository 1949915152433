import { BilibiliOutlined, DashboardOutlined, LogoutOutlined, MailOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ScheduleOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, MenuProps, Space, Spin, Form, Select, Row, Flex, FormProps, message, Button } from 'antd';
import React, { FC, ReactNode, Suspense, useEffect, useState } from "react";
import { useStore } from '../../store';
import { Link, useLocation } from 'react-router-dom';
import { FetchResponse, Site } from '../../models';
import { SiteService } from '../../services';

const { Header, Content, Footer, Sider } = Layout;

interface LayoutProps {
	children: ReactNode;
}

const RootPage: FC<LayoutProps> = ({ children }) => {
	const { state: { user, currentSite, refreshSite }, dispatch } = useStore()
	const l = useLocation()
	const [activeKeys, setActiveKeys] = useState([l.pathname])
	const [sites, setSites] = useState<Site[]>([]);
	const [messageApi, contextHolder] = message.useMessage();
	const [collapsed, setCollapsed] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			let result: FetchResponse = await SiteService.getUserSites();
			if (result?.status === "success") {
				const sites: Site[] = result.data;
				if (sites?.length > 0) {
					setSites(sites);
				} else {
					setSites([]);
				}
			}
			console.log("refresh site");
		};
		fetch();
	}, [refreshSite]);

	const onClick: MenuProps['onClick'] = (e) => {
		if (e.key === 'logout') {
			dispatch({ payload: true, type: 'LOGOUT' })
		} else {
			setActiveKeys([e.key])
		}
	};

	const items = () => {
		if (user?.type === 'admin') {
			return [
				getItem(<Link className='txt-white' to={'/'}>Dashboard</Link>, '/', <DashboardOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/tarifs'}>Mode de calcul</Link>, '/tarifs', <BilibiliOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/mail-setting'}>Envoi mail</Link>, '/mail-setting', <MailOutlined className='txt-white' />),
			]
		} else if (user?.type === 'manager') {
			return [
				getItem(<Link className='txt-white' to={'/'}>Gestion relevé</Link>, '/', <ScheduleOutlined className='txt-white' />),
				getItem(<Link className='txt-white' to={'/customer'}>Client</Link>, '/customer', <UserOutlined className='txt-white' />),
			]
		}
	}

	const onValuesChange: FormProps<FormType>['onValuesChange'] = async (data) => {
		try {
			const result: any = await SiteService.setDefaultSite({ siteId: data.site });
			if (result.status === "success") {
				const currentSite: Site = sites.filter((item) => item.id === data.site)[0];
				dispatch({ payload: currentSite, type: 'SET_CURRENTSITE' });
			} else {
				messageApi.open({
					type: 'error',
					content: 'Erreur lors du changement du site',
				});
			}
		} catch (e) {
			console.info("saving site error: ", e);
			messageApi.open({
				type: 'error',
				content: 'Erreur lors du changement du site',
			});
		}
	}

	return (
		<Layout style={{ minHeight: '100vh' }}>
			{contextHolder}
			<Sider
				breakpoint="lg"
				collapsedWidth="0"
				collapsed={collapsed}
				onBreakpoint={(broken) => {
					console.log(broken);
				}}
				onCollapse={(collapsed, type) => {
					console.log(collapsed, type);
				}}
				className='blue-gradient-background txt-white sider-shadow'
			>
				<Space direction='horizontal' size="small">
					<div className="awater-logo">
						<img
							style={{ borderRadius: '50%' }}
							src={`assets/images/logo.png`}
						/>
					</div>
					<div>
						<span style={{ fontWeight: 'bold', color: 'white' }}>A-Water</span>
					</div>
				</Space>
				<Space direction='horizontal' size="small">
					<div className="awater-logo">
						<img
							style={{ borderRadius: '50%' }}
							src={`assets/images/profil.png`}
						/>
					</div>
					<div>
						<span style={{ fontWeight: 'bold', color: '#bababa' }}>{user?.fistName} {user?.lastName}</span>
					</div>
				</Space>
				<Menu defaultSelectedKeys={activeKeys} style={{ backgroundColor: 'transparent' }} onClick={onClick} mode="inline" items={items()} />
			</Sider>
			<Layout>
				<Header style={{ padding: 0, height: 60 }} className='bg-blue txt-white'>
					<Flex style={{ height: '100%' }} justify='space-between' align='center'>
						<Button
							type="text"
							className='txt-white'
							icon={collapsed ? <MenuUnfoldOutlined style={collapseBtnStyle} /> : <MenuFoldOutlined style={collapseBtnStyle} />}
							onClick={() => setCollapsed(!collapsed)}
							style={{
								width: 64,
								height: 64,
							}}
						/>
						<Flex style={{ height: '100%' }} justify='center' align='center' flex={1}>
							<Row justify={"center"}>
								{currentSite.id && <Form
									name="global_site"
									layout="inline"
									onValuesChange={onValuesChange}
								>
									<Form.Item
										label=""
										name={"site"}
										rules={[{ required: true, message: 'Veuillez choisir un site' }]}
										initialValue={currentSite.id}
									>
										<Select
											options={sites}
											fieldNames={{
												label: "label",
												value: "id",
											}}
											key={"id"}
											style={{ width: 200 }}
											placeholder="Tous"
											optionFilterProp="children"
											filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
											filterSort={(optionA, optionB) =>
												(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
											}
										/>
									</Form.Item>
								</Form>}
							</Row>
						</Flex>
						<Space>
							<LogoutOutlined style={{
								marginRight: 20,
								fontSize: 25,
								paddingTop: '21px'
							}} onClick={() => {
								dispatch({ payload: true, type: 'LOGOUT' });
							}} />
						</Space>
					</Flex>
				</Header>
				<Content>
					<div
						style={{
							padding: 25,
							paddingBottom: 0,
							minHeight: 360,
						}}
					>
						<Suspense fallback={<div style={{ position: 'absolute', top: '50%', left: '50%' }}><Spin size="large" /></div>}>
							{children}
						</Suspense>
					</div>
				</Content>
				<Footer style={{ textAlign: 'center' }}>
					Ant Design ©{new Date().getFullYear()} Created by Ant UED
				</Footer>
			</Layout>
		</Layout>
	)
};

const collapseBtnStyle: React.CSSProperties = {
	fontSize: 25,
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
	label: React.ReactNode,
	key: React.Key,
	icon?: React.ReactNode,
	children?: MenuItem[],
	type?: 'group',
): MenuItem {
	return {
		key,
		icon,
		children,
		label,
		type,
	} as MenuItem;
}

class FormType {
	site: number = 0;
}

export default RootPage;