import { useRef, useState } from "react";
import {
  Space,
  Button,
  Input,
  InputRef,
  TableColumnType,
  Checkbox,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/es/table/interface";
import Highlighter from "react-highlight-words";

export const useColumnFilter = <T,>() => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState<keyof T>();
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps["confirm"],
    dataIndex: keyof T
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: keyof T,
    select: boolean = false,
    data: T[] = [],
    transform: (value: any) => string = (value: any) => value
  ): TableColumnType<T> =>
    select
      ? {
          filters: data.reduce((prev, curr) => {
            const _value = transform(curr[dataIndex]);
            const found = prev.find((p) => p.value === _value);
            if (!found) {
              prev.push({
                text: _value,
                value: _value,
              });
            }
            return prev;
          }, [] as any[]),
          onFilter: (value, record: any) => new RegExp(value as string, "gi").test(transform(record[dataIndex as string])),
          filterDropdown: ({
            close,
            confirm,
            selectedKeys,
            setSelectedKeys,
            clearFilters,
            filters,
          }) => {
            const handleCheckboxChange = (
              checkedValue: string,
              checked: boolean
            ) => {
              if (checked) {
                setSelectedKeys([...selectedKeys, checkedValue]);
              } else {
                setSelectedKeys(
                  selectedKeys.filter((key: any) => key !== checkedValue)
                );
              }
            };
            return (
                <Space direction="vertical" style={{ padding: 8, maxHeight: 300, overflowY: 'auto' }}>
                  {filters?.map((filter) => (
                    <div key={filter.value as string}>
                      <Checkbox
                        key={filter.value as string}
                        checked={selectedKeys.includes(filter.value as string)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            filter.value as string,
                            e.target.checked
                          )
                        }
                      >
                        {filter.text}
                      </Checkbox>
                    </div>
                  ))}
                  <Space direction="horizontal">
                    <Button
                      onClick={() => {
                        if(clearFilters){
                          clearFilters()
                        }
                        confirm({ closeDropdown: true });
                      }}
                      style={{ width: "100%" }}
                      size="small"
                    >
                      Réinitialiser
                    </Button>
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        confirm({ closeDropdown: true });
                        setSearchText((selectedKeys as string[])[0]);
                        setSearchedColumn(dataIndex);
                      }}
                      style={{ width: "100%" }}
                    >
                      Ok
                    </Button>
                  </Space>
              </Space>
            );
          },
        }
      : {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
          }: any) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
              <Input
                ref={searchInput}
                placeholder={`Rechercher ...`}
                value={selectedKeys[0]}
                onChange={(e) =>
                  setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() =>
                  handleSearch(selectedKeys as string[], confirm, dataIndex)
                }
                style={{ marginBottom: 8, display: "block" }}
              />
              <Space>
                <Button
                  type="primary"
                  onClick={() =>
                    handleSearch(selectedKeys as string[], confirm, dataIndex)
                  }
                  size="small"
                  style={{ width: 90 }}
                >
                  Rechercher
                </Button>
                <Button
                  onClick={() => clearFilters && handleReset(clearFilters)}
                  size="small"
                  style={{ width: 90 }}
                >
                  Réinitialiser
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    confirm({ closeDropdown: false });
                    setSearchText((selectedKeys as string[])[0]);
                    setSearchedColumn(dataIndex);
                  }}
                >
                  Filtrer
                </Button>
                <Button
                  type="link"
                  size="small"
                  onClick={() => {
                    close();
                  }}
                >
                  Fermer
                </Button>
              </Space>
            </div>
          ),
          filterIcon: (filtered: boolean) => (
            <SearchOutlined
              style={{ color: filtered ? "#fff" : "#0000004a" }}
            />
          ),
          onFilter: (value, record) =>
            (record[dataIndex] as any)
              .toString()
              .toLowerCase()
              .includes((value as string).toLowerCase()),
          onFilterDropdownOpenChange: (visible) => {
            if (visible) {
              setTimeout(() => searchInput.current?.select(), 100);
            }
          },
          render: (text) =>
            searchedColumn === dataIndex ? (
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ""}
              />
            ) : (
              text
            ),
        };

  return { getColumnSearchProps };
};
